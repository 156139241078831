export default [
    { id: 10000002, key: 'Ayaka', name: 3561, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000003, key: 'Qin', name: 3562, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000005, key: 'PlayerBoy', name: 3563, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000006, key: 'Lisa', name: 3564, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000007, key: 'PlayerGirl', name: 3565, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000014, key: 'Barbara', name: 3566, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000015, key: 'Kaeya', name: 3567, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000016, key: 'Diluc', name: 3568, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000020, key: 'Razor', name: 3569, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000021, key: 'Ambor', name: 3570, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000022, key: 'Venti', name: 3571, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000023, key: 'Xiangling', name: 3572, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000024, key: 'Beidou', name: 3573, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000025, key: 'Xingqiu', name: 3574, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000026, key: 'Xiao', name: 3575, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000027, key: 'Ningguang', name: 3576, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000029, key: 'Klee', name: 3577, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000030, key: 'Zhongli', name: 3578, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000031, key: 'Fischl', name: 3579, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000032, key: 'Bennett', name: 3580, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000033, key: 'Tartaglia', name: 3581, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000034, key: 'Noel', name: 3582, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000035, key: 'Qiqi', name: 3583, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000036, key: 'Chongyun', name: 3584, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000037, key: 'Ganyu', name: 3585, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000038, key: 'Albedo', name: 3586, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000039, key: 'Diona', name: 3587, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000041, key: 'Mona', name: 3588, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000042, key: 'Keqing', name: 3589, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000043, key: 'Sucrose', name: 3590, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000044, key: 'Xinyan', name: 3591, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000045, key: 'Rosaria', name: 3592, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000046, key: 'Hutao', name: 3593, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000047, key: 'Kazuha', name: 3594, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000048, key: 'Feiyan', name: 3595, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000049, key: 'Yoimiya', name: 3596, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000050, key: 'Tohma', name: 3597, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000051, key: 'Eula', name: 3598, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000052, key: 'Shougun', name: 3599, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000053, key: 'Sayu', name: 3600, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000054, key: 'Kokomi', name: 3601, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000055, key: 'Gorou', name: 3602, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000056, key: 'Sara', name: 3603, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000057, key: 'Itto', name: 3604, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000058, key: 'Yae', name: 3605, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000059, key: 'Heizo', name: 3606, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000060, key: 'Yelan', name: 3607, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000061, key: 'Momoka', name: 3608, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000062, key: 'Aloy', name: 3609, weapon: 'Bow', quailty: 'OrangeSp' },
    { id: 10000063, key: 'Shenhe', name: 3610, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000064, key: 'Yunjin', name: 3611, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000065, key: 'Shinobu', name: 3612, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000066, key: 'Ayato', name: 3613, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000067, key: 'Collei', name: 3614, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000068, key: 'Dori', name: 3615, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000069, key: 'Tighnari', name: 3616, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000070, key: 'Nilou', name: 3617, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000071, key: 'Cyno', name: 3618, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000072, key: 'Candace', name: 3619, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000073, key: 'Nahida', name: 3620, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000074, key: 'Layla', name: 3621, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000075, key: 'Wanderer', name: 3622, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000076, key: 'Faruzan', name: 3623, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000077, key: 'Yaoyao', name: 3624, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000078, key: 'Alhatham', name: 3625, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000079, key: 'Dehya', name: 3626, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000080, key: 'Mika', name: 3627, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000081, key: 'Kaveh', name: 3628, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000082, key: 'Baizhuer', name: 3629, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000083, key: 'Linette', name: 3630, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000084, key: 'Liney', name: 3631, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000085, key: 'Freminet', name: 3632, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000086, key: 'Wriothesley', name: 3633, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000087, key: 'Neuvillette', name: 3634, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000088, key: 'Charlotte', name: 3635, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000089, key: 'Furina', name: 3636, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000090, key: 'Chevreuse', name: 3637, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000091, key: 'Navia', name: 3638, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000092, key: 'Gaming', name: 3639, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000093, key: 'Liuyun', name: 3640, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000094, key: 'Chiori', name: 3641, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000095, key: 'Sigewinne', name: 3642, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000096, key: 'Arlecchino', name: 3643, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000097, key: 'Sethos', name: 3644, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000098, key: 'Clorinde', name: 3645, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000099, key: 'Emilie', name: 3646, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000100, key: 'Kachina', name: 3647, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000101, key: 'Kinich', name: 3648, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000102, key: 'Mualani', name: 3649, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000103, key: 'Xilonen', name: 3650, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000104, key: 'Chasca', name: 3651, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000105, key: 'Olorun', name: 3652, weapon: 'Bow', quailty: 'Purple' },
]
